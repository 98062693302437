import * as React from 'react';

import { Heading } from '@msdyn365-commerce-modules/utilities';
import { IImageSettings, Image, RichTextComponent } from '@msdyn365-commerce/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { ITimelineItemsData, ITimelineProps } from './timeline.props.autogenerated';

const HEIGHT:number = 400;
/**
 *
 * Timeline component
 * @extends {React.Component<ITimelineProps<{}>>}
 */
@observer
class Timeline extends React.Component<ITimelineProps<{}>> {

    // =========================================================================
    // OBSERVABLES AND FIELDS
    // =========================================================================

    @observable private currentSlide: number;
    @observable private timelineItems: ITimelineItemsData[];
    @observable private timelineItemTransition: string;

    private yearPositions: number[] = [];
    private yearCenter: number = 0;

    private minimalImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=0&h=${HEIGHT}&m=6`, w: 0, h: HEIGHT },
            lg: { q: `w=0&h=${HEIGHT}&m=6`, w: 0, h: HEIGHT },
            xl: { q: `w=0&h=${HEIGHT}&m=6`, w: 0, h: HEIGHT }
        },
        disableLazyLoad: true
    };

    // ------------------------------------------------------
    // ------------------------------------------------------
    constructor(props: ITimelineProps<{}>) {
        super(props);
        this.currentSlide = 0;
        this.timelineItems = props.config.timelineItems || [];
        this.timelineItemTransition = 'timeline-item-show';

        this._nextItem = this._nextItem.bind(this);
        this._previousItem = this._previousItem.bind(this);
        this._handleItemChange = this._handleItemChange.bind(this);

        if (props.config.timelineInterval) {
            setInterval(this._nextItem, props.config.timelineInterval);
        }
    }

    // =========================================================================
    // PUBLIC METHODS
    // =========================================================================

    // ------------------------------------------------------
    // ------------------------------------------------------
    public componentDidMount(): void {
        // Get the relative position of all items in the year scroller
        this._getYearPositions();
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    public render(): JSX.Element | null {
        const { config } = this.props;
        if (!config.timelineItems) {
            return null;
        }

        const currentItem = config.timelineItems[this.currentSlide];
        const gridSettings = this.props.context.actionContext.requestContext.gridSettings;

        return (
            <div className='timeline'>

                <div className='timeline-scroll-hidden'>
                    <div className='timeline-scroll-container' style={{paddingTop:`${HEIGHT-50}px`,paddingBottom:`${HEIGHT-50}px`}}>
                        {this._getSelectors}
                    </div>
                </div>

                <div className={`timeline-entry ${currentItem.class || ''}`}>
                    <div className='timeline-previous-button' tabIndex={0} role='button' onClick={this._previousItem} aria-label={'Previous Year'} />
                    <div className={this.timelineItemTransition}>
                        {gridSettings && currentItem.image &&
                            <Image {...currentItem.image} gridSettings={gridSettings} imageSettings={this.minimalImageSettings} className='timeline-item-image' />
                        }
                        <RichTextComponent text={currentItem.text as string} />
                    </div>
                    <div className='timeline-next-button' tabIndex={0} role='button' onClick={this._nextItem}  aria-label={'Next Year'} />
                </div>

            </div>
        );
    }

    // =========================================================================
    // PRIVATE METHODS
    // =========================================================================

    // ------------------------------------------------------
    // ------------------------------------------------------
    private get _getSelectors(): JSX.Element | null {
        return (
            <React.Fragment>
                {this.timelineItems.map((timelineItem, index) => (
                    <div
                        className={index === this.currentSlide ? 'timeline-year timeline-current-selector' : 'timeline-year timeline-selector'}
                        role='button'
                        tabIndex={0}
                        onClick={
                            this._handleItemChange
                        }
                        data-id={index}
                        key={index}
                    >
                        <Heading
                            {...timelineItem.timelineYear}
                            headingTag='h2'
                        />
                    </div>
                ))}
            </React.Fragment>
        );
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _previousItem(): void {
        this._handleItemTransition();
        this.currentSlide = this.currentSlide > 0 ? this.currentSlide - 1 : this.timelineItems.length - 1;
        this._recenter();
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _nextItem(): void {
        this._handleItemTransition();
        this.currentSlide = (this.currentSlide < this.timelineItems.length - 1) ? this.currentSlide + 1 : 0;
        this._recenter();
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _handleItemChange(element: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
        const index = element.currentTarget.getAttribute('data-id')!;
        this.currentSlide = +index;
        this._handleItemTransition();
        this._recenter(element.currentTarget);
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _recenter(el?: EventTarget & HTMLDivElement): void {

        const desiredPosition = this.yearPositions[this.currentSlide];
        const container = document.getElementsByClassName('timeline-scroll-hidden').item(0);

        container && container.scrollTo({
            top: desiredPosition - this.yearCenter,
            left: 0,
            behavior: 'smooth'
        });
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _handleItemTransition(): void {
        this.timelineItemTransition = '';
        setTimeout(() => {
            this.timelineItemTransition = 'timeline-item-show';
        },         0);
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _getYearPositions(): void {
        const years = document.getElementsByClassName('timeline-year');

        for (let i = 0; i < years.length; i++) {
            const el = years[i] as HTMLElement;
            this.yearPositions[i] = el.offsetTop;
        }

        const scrollContainer = document.getElementsByClassName('timeline-scroll-hidden')[0] as HTMLElement;
        if (scrollContainer) {
            this.yearCenter = scrollContainer.offsetHeight / 2;
        }

    }
}

export default Timeline;
