import { mapToCssModules } from '@msdyn365-commerce-modules/utilities';
import classNames from 'classnames';
import * as React from 'react';
import { IFormProps } from './Form.props';

/**
 * Form component
 */
export default class Form extends React.Component<IFormProps> {

    public static defaultProps: Partial<IFormProps> = {
    };
    public props: IFormProps;
    private ref: React.RefObject<HTMLFormElement>;

    constructor(props: IFormProps) {
        super(props);
        this.props = props;
        this.ref = React.createRef();
    }

    public submit(): void {
        if (this.ref && this.ref.current) {
            this.ref.current.submit();
        }
    }

    public render(): JSX.Element {
        const {
            className,
            cssModule,
            inline,
            tag,
            innerRef,
            'aria-label': label,
            role,
            ...attributes
        } = this.props;

        const Tag = tag || 'form';

        const classes = mapToCssModules(
            classNames(
                className,
                inline ? 'form-inline' : false
            ),
            cssModule
        );

        return (
            <Tag {...attributes} ref={innerRef} className={classes} role={'form'} aria-label={label} />
        );
    }
}
