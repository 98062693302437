import * as React from 'react';
import { TextInputGroup } from '../modules/mailing-list-form/components/text-input-group';

export type mailingData = {
    name: string;
    email: string;
    zip: string;
};

export type inputGroup = {
    labelText?: string;
    validationText?: string;
    placeholder?: string;
    ariaLabel?: string;
};

interface IMailingListInputGroup {
    submitText?: string;
    name: inputGroup;
    zip: inputGroup;
    email: inputGroup;
    onFinish(dataSet: mailingData): void;
}

type mailingListFormStructureGroup = {
    validity: boolean;
    value: string;
    externalGroup?: inputGroup;
    regex?: RegExp;
};

type mailingListFormStructure = {
    [fieldName: string]: mailingListFormStructureGroup;
};

/**
 * Constructs only the necessary components of the mailing list inputs
 *
 * To add a new field, add it to the internal form structure object and
 * the external props typing
 */
export class MailingListInputGroup extends React.Component<IMailingListInputGroup> {

    private _formStructure: mailingListFormStructure = {
        name: {
            validity: false,
            value: ''
        },
        email: {
            validity: false,
            value: '',
            regex: /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]+(?:-+[a-z0-9]+)*\.)+(?:xn--[a-z0-9]+|[a-z]{2,16})$/i
        },
        zip: {
            validity: false,
            value: '',
            regex: /^[0-9]{5}(?:-[0-9]{4})?$/
        }
    };

    constructor(props: IMailingListInputGroup) {
        super(props);

        this._handleValidityUpdate = this._handleValidityUpdate.bind(this);
        this._handleValueFinish = this._handleValueFinish.bind(this);
        this._handleFormExport = this._handleFormExport.bind(this);
    }

    public render(): JSX.Element {
        this._setExternalGroups();
        return (
            <React.Fragment>
                {Object.keys(this._formStructure).map(fieldName => {
                    const field = this._formStructure[fieldName];
                    let redirectEmail;
                    // tslint:disable-next-line: no-typeof-undefined
                    if (typeof window !== 'undefined') {
                        const urlQuery = window.location.search;
                        const urlParams = new URLSearchParams(urlQuery);
                        redirectEmail = urlParams.get('mailFormEmail') || undefined;
                    }

                    return (
                        <TextInputGroup
                            key={fieldName}
                            label={field.externalGroup!.labelText}
                            ariaLabel={field.externalGroup!.ariaLabel}
                            placeholder={field.externalGroup!.placeholder}
                            id={fieldName}
                            onChange={this._handleValueFinish}
                            isValid={this._handleValidityUpdate}
                            initialValue={fieldName === 'email' ? redirectEmail : undefined}
                        />
                    );
                })}
                <button
                    className='input-group-mailing-list-button'
                    onClick={this._handleFormExport}
                    disabled={!this._isFormValid()}
                >
                    {this.props.submitText || 'Submit'}
                </button>
            </React.Fragment>
        );
    }

    private _setExternalGroups(): void {
        this._formStructure.name.externalGroup = this.props.name;
        this._formStructure.email.externalGroup = this.props.email;
        this._formStructure.zip.externalGroup = this.props.zip;
    }

    private _handleFormExport(): void {
        this.props.onFinish({
            email: this._formStructure.email.value,
            name: this._formStructure.name.value,
            zip: this._formStructure.zip.value
        });
    }

    private _isFormValid(): boolean {
        const containsInvalidField = !!Object.keys(this._formStructure)
            .find((fieldName) => {
                const field = this._formStructure[fieldName];
                return !field.validity;
            });
        return !containsInvalidField;
    }

    private _handleValueFinish(value: string, isValid: boolean, id: string): void {
        const fieldData = this._formStructure[id];
        fieldData.validity = isValid;
        fieldData.value = value;

        // re-render, no reason to use mobx for this
        this.setState({});
    }

    private _handleValidityUpdate(value: string, id: string): string | void {
        const fieldData = this._formStructure[id];

        // should always be true but just in case
        if (fieldData) {
            const validationText = fieldData.externalGroup!.validationText;
            if (value.length === 0) { return validationText; }
            if (fieldData.regex && !fieldData.regex.test(value)) { return validationText; }
        }
    }
}