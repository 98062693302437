import { IGridSettings, IImageData, IImageSettings, Image } from '@msdyn365-commerce/core';
import * as React from 'react';
import TrueTourIFrame from '../../components/truetour-iframe';
import VimeoIFrame from '../../components/vimeo-iframe';
import { IContentWithMediaConfig } from './content-with-media.props.autogenerated';

// ==============================================================================
// TYPE DEFINITIONS
// ==============================================================================

interface IMediaContentBlockProps {
    config: IContentWithMediaConfig;
    imageGridSettings?: IGridSettings;
}

// ==============================================================================
// CLASS DEFINITIONS
// ==============================================================================

/**
 * class responsible for the rendering of media content as an image or from vimeo
 */
class MediaContentBlockComponent extends React.Component<IMediaContentBlockProps> {

    // ==========================================================================
    // PUBLIC METHODS
    // ==========================================================================

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    public render(): JSX.Element {
        return (
            <div className='content-with-media-media'>
                {this._media}
            </div>
        );
    }

    // ==========================================================================
    // JSX CONSTRUCTORS
    // ==========================================================================

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _media(): JSX.Element | null {
        const {
            mediaDisplayImage,
            mediaDisplayVimeo,
            mediaDisplayTrueTour
        } = this.props.config;

        if (mediaDisplayImage) {
            return this._imageMedia;
        }

        if (mediaDisplayVimeo) {
            return this._vimeoMedia;
        }

        if (mediaDisplayTrueTour) {
            return this._tourMedia;
        }

        console.warn('MEDIA DISPLAY IS NOT SET');
        return null;
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _vimeoMedia(): JSX.Element {
        const {
            mediaDisplayVimeo
        } = this.props.config;

        return (
            <div className='content-with-media-media-video'>
                <VimeoIFrame vimeoId={mediaDisplayVimeo!} />
            </div>
        );
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _tourMedia(): JSX.Element {
        const {
            mediaDisplayTrueTour
        } = this.props.config;

        return (
            <div className='content-with-media-media-video'>
                <TrueTourIFrame trueTourId={mediaDisplayTrueTour!} />
            </div>
        );
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _imageMedia(): JSX.Element {
        const {
            mediaDisplayImage,
            mediaDisplayBackdrop
        } = this.props.config;

        return (
            <div className='content-with-media-media-image'>
                {this._constructImage(mediaDisplayImage)}
                {this._constructImage(mediaDisplayBackdrop)}
            </div>
        );
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private _constructImage(image?: IImageData): JSX.Element | null {
        if (image) {
            const defaultImageSettings: IImageSettings = {
                viewports: {
                    xs: { q: `w=24&h=24&m=6`, w: 0, h: 0 },
                    lg: { q: `w=24&h=24&m=6`, w: 0, h: 0 }
                },
                lazyload: true
            };

            return (
                <Image
                    {...image}
                    gridSettings={this.props.imageGridSettings!}
                    imageSettings={image.imageSettings || defaultImageSettings}
                />
            );
        }

        // No image to construct
        return null;
    }
}

export default MediaContentBlockComponent;