import { Heading, Modal, ModalHeader } from '@msdyn365-commerce-modules/utilities';
import { getRichTextHtml, IGridSettings, IImageData, IImageSettings, Image, RichText } from '@msdyn365-commerce/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import ModalBody from 'reactstrap/lib/ModalBody';
import { ITextCallToActionLinkData, ITextCategoryData, ITextTitleData } from '../modules/content-with-media/content-with-media.props.autogenerated';
import CallToAction from './call-to-action';
import YoutubeIFrame from './youtube-iframe';

// ==============================================================================
// TYPE DEFINITIONS
// ==============================================================================

interface ITextContentBlockProps {
    config: {
        backgroundImage?: IImageData;
        callToActionLink?: ITextCallToActionLinkData;
        callToActionYoutube?: string;
        callToActionText?: string;
        category?: ITextCategoryData;
        title?: ITextTitleData;
        summary?: string;
        copy?: RichText;
        className?: string;
        contentBlockNoHeadings?: React.ReactNode[];
    };

    imageGridSettings?: IGridSettings;
}

// ==============================================================================
// CLASS DEFINITIONS
// ==============================================================================

/**
 * class definition containing all rendering regarding the text side of the
 * generic content block
 */
@observer
class TextContentBlockComponent extends React.Component<ITextContentBlockProps> {

    // ==========================================================================
    // FIELDS
    // ==========================================================================

    @observable private _isModalOpen: boolean = false;

    // ==========================================================================
    // PUBLIC METHODS
    // ==========================================================================

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    public constructor(props: ITextContentBlockProps) {
        super(props);

        this._modalStateToFalse = this._modalStateToFalse.bind(this);
        this._modalStateToTrue = this._modalStateToTrue.bind(this);
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    public render(): JSX.Element {
        return (
            <div className={this.props.config.className}>
                {this._backgroundImage}
                {this._category}
                {this._title}
                {this._summary}
                {this._copy}
                {this._callToAction}
            </div>
        );
    }

    // ==========================================================================
    // COMPONENT EVENTS
    // ==========================================================================

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private _modalState(newState: boolean): void {
        this._isModalOpen = newState;
    }

    // -----------------------------------------------------------
    // extrapolated _modalState for use in a callback
    // -----------------------------------------------------------
    private _modalStateToFalse(): void {
        this._modalState(false);
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private _modalStateToTrue(): void {
        this._modalState(true);
    }

    // ==========================================================================
    // JSX CONSTRUCTORS
    // ==========================================================================

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _copy(): JSX.Element | null {
        const { copy, contentBlockNoHeadings } = this.props.config;
        if (copy) {
            if (contentBlockNoHeadings) {
                return (
                    <>
                        <div
                            dangerouslySetInnerHTML={getRichTextHtml(copy)}
                        />
                        <>{contentBlockNoHeadings}</>
                    </>
                );
            }
            return (
                // tslint:disable-next-line:react-no-dangerous-html
                <div
                    dangerouslySetInnerHTML={getRichTextHtml(copy)}
                />

            );
        }
        if (contentBlockNoHeadings) {
            return (
                <>{contentBlockNoHeadings}</>
            );
        }
        else { return null; }
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _title(): JSX.Element | null {
        const title = this.props.config.title;

        if (title && title?.text) {
            return (
                <Heading
                    {...title}
                />
            );
        } else { return null; }
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _summary(): JSX.Element | null {
        const summary = this.props.config.summary;

        if (summary) {
            return (<p>{summary}</p>);
        } else { return null; }
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _category(): JSX.Element | null {
        const category = this.props.config.category;

        if (category && category?.text) {
            return (
                <Heading
                    {...category}
                />
            );
        } else { return null; }
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _backgroundImage(): JSX.Element | null {
        const image = this.props.config.backgroundImage;

        const defaultImageSettings: IImageSettings = {
            viewports: {
                xs: { q: `w=24&h=24&m=6`, w: 0, h: 0 },
                lg: { q: `w=24&h=24&m=6`, w: 0, h: 0 }
            },
            lazyload: true
        };

        if (image) {
            return (
                <Image
                    {...image}
                    gridSettings={this.props.imageGridSettings!}
                    imageSettings={image.imageSettings || defaultImageSettings}
                />
            );
        } else { return null; }
    }

    // -----------------------------------------------------------
    /**
     * @returns anchor that sends you a link or clickable div that opens a modal
     */
    // -----------------------------------------------------------
    private get _callToAction(): JSX.Element | null {
        const {
            callToActionYoutube,
            callToActionLink: ctaLink
        } = this.props.config;

        // If there's a YouTube ID, assume it's in a modal
        if (callToActionYoutube) {
            return this._callToActionInModal;
        }

        // If there's a link (and not YouTube ID), display the CTA as a link
        if (ctaLink && ctaLink.linkUrl.destinationUrl && ctaLink.linkText) {
            return (
                <CallToAction
                    title={ctaLink.linkText}
                    href={ctaLink.linkUrl.destinationUrl}
                    openInNewTab={ctaLink.openInNewTab}
                    aria-label={ctaLink.ariaLabel ? ctaLink.ariaLabel : ctaLink.linkText}
                />
            );
        }

        return null;
    }

    // -----------------------------------------------------------
    // -----------------------------------------------------------
    private get _callToActionInModal(): JSX.Element {
        const {
            callToActionText,
            callToActionYoutube
        } = this.props.config;
        const modalId = `youtube-${callToActionYoutube}`;

        return (
            <div>
                <div
                    onClick={this._modalStateToTrue}
                    onKeyPress={this._modalStateToTrue}
                    role='button'
                    className='youtube-modal-button'
                    tabIndex={0}
                >
                    {callToActionText}
                </div>
                <Modal
                    isOpen={this._isModalOpen}
                    toggle={this._modalStateToFalse}
                    modalClassName='youtube-modal'
                    aria-labelledby={modalId}
                >
                    <ModalHeader toggle={this._modalStateToFalse} />
                    <ModalBody>
                        {this._isModalOpen &&
                            <YoutubeIFrame youtubeId={callToActionYoutube} />
                        }
                    </ModalBody>
                </Modal>
            </div>
        );

    }
}

export default TextContentBlockComponent;