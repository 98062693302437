import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

interface ITextInputGroupProps {
    useDatePicker?: boolean;
    minDate?: string;
    label?: string;
    ariaLabel?:string;
    placeholder?: string;
    id: string;
    initialValue?: string;
    htmlProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    isValid(curValue: string, id: string): string | void;
    onChange(value: string, currentValidity: boolean, id: string): void;
}

/**
 * TextInputGroup is a wrapper that allows you to validate text values from the
 * user and returns the correct value after
 */
@observer
export class TextInputGroup extends React.Component<ITextInputGroupProps> {

    @observable private _internalValue: string = '';
    @observable private _errorMessage: string = '';
    @observable private _isFirstBlur: boolean = true;

    constructor(props: ITextInputGroupProps) {
        super(props);

        if (props.initialValue) {
            this._internalValue = props.initialValue;
            const currentlyInvalid = this.props.isValid(this._internalValue, this.props.id);
            const isValid = (currentlyInvalid) ? false : true;
            this.props.onChange(this._internalValue, isValid, this.props.id);
        }

        this._valueChanged = this._valueChanged.bind(this);
        this._blurred = this._blurred.bind(this);
    }

    public render(): JSX.Element {
        const typeSettings = this.props.useDatePicker ?
        {
            type: 'date',
            min: this.props.minDate
        } :
        { type: 'text' };

        return (
            <div className='form-group text-input-group'>
                <label htmlFor={this.props.label}>
                    {this.props.label}
                    <span aria-hidden='true'>{'*'}</span>
                </label>
                <input
                    placeholder={this.props.placeholder}
                    id={this.props.label}
                    required
                    className='form-control'
                    aria-label={this.props.ariaLabel}
                    {...typeSettings}
                    onChange={this._valueChanged}
                    value={this._internalValue}
                    autoComplete='off'
                    onBlur={this._blurred}
                    {...this.props.htmlProps}
                />
                <div aria-live='polite'>{this._jsxErrorMessage} </div>
            </div>
        );
    }

    private _blurred(event: React.ChangeEvent<HTMLInputElement>): void {
        this._isFirstBlur = false;
        this._valueChanged(event);
    }

    private _valueChanged(event: React.ChangeEvent<HTMLInputElement>): void {
        this._internalValue = event.target.value;
        const currentlyInvalid = this.props.isValid(this._internalValue, this.props.id);
        const isValid = (currentlyInvalid) ? false : true;

        // we only want to show an error if a user has clicked away and its
        // still wrong
        if (currentlyInvalid && !this._isFirstBlur) {
            this._errorMessage = currentlyInvalid;
        } else {
            this._errorMessage = '';
        }

        this.props.onChange(this._internalValue, isValid, this.props.id);
    }

    private get _jsxErrorMessage(): JSX.Element | void {
        if (this._errorMessage.length > 0) {
            return (
                <p className='alert alert-danger text-input-validation-error'>
                    {this._errorMessage}
                </p>
            );
        }
    }
}