import * as React from 'react';

interface ITourIFrameProps {
    trueTourId: string;
}

declare module 'react' {
    // tslint:disable-next-line:interface-name
    interface HTMLAttributes<T> extends DOMAttributes<T> {
        frameborder?: string;
    }
}

/**
 * iframe renderer for truetour
 */
class TourIFrame extends React.Component<ITourIFrameProps> {
    public render(): JSX.Element | null {
        const {
            trueTourId
        } = this.props;
        const iframeId = `truetour-${trueTourId}`;

        if (!trueTourId) {
            console.warn('TrueTour: Missing TrueTour ID');
            return null;
        }

        return (
            <iframe
                // tslint:disable-next-line: react-iframe-missing-sandbox
                sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
                src={`https://visitingmedia.com/tt8/?ttid=chateau-ste-michelle-icms/#/${trueTourId}`}
                frameBorder='0'
                allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen'
                title='TrueTour Video'
                id={iframeId}
            />
        );
    }
}

export default TourIFrame;