import * as React from 'react';

interface IVimeoIFrameProps {
    vimeoId: string;
}

declare module 'react' {
    // tslint:disable-next-line:interface-name
    interface HTMLAttributes<T> extends DOMAttributes<T> {
        frameborder?: string;
        allowfullscreen?: unknown;
    }
}

/**
 * class that renders a vimeo iframe
 */
class VimeoIFrame extends React.Component<IVimeoIFrameProps> {
    public render(): JSX.Element | null {
        const {
            vimeoId
        } = this.props;
        const iframeId = `vimeo-${vimeoId}`;

        if (!vimeoId) {
            console.warn('Vimeo Video: Missing Vimeo ID');
            return null;
        }

        return (
            <iframe
                // tslint:disable-next-line: react-iframe-missing-sandbox
                sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
                src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&background=1`}
                frameBorder='0'
                allow='autoplay; fullscreen'
                allowFullScreen
                title='Vimeo Video'
                id={iframeId}
            />
        );
    }
}

export default VimeoIFrame;